import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import styled from "styled-components"

/*
  ⚠️ This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/

const Form = styled.form`
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  input,
  textarea {
    padding: 10px;
    &::-webkit-input-placeholder {
      color: gray;
    }
    &::-moz-placeholder {
      color: gray;
    }
    &:-ms-input-placeholder {
      color: gray;
    }
    &:-moz-placeholder {
      color: gray;
    }
    &:required {
      box-shadow: none;
    }
  }
  &::before {
    content: "";
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? ".8" : "0")};
    visibility: ${props => (props.overlay ? "visible" : "hidden")};
  }
`

const Name = styled.input`
  margin: 0 1em 1em 0;
  width: 48%;
`

const Email = styled.input`
  margin: 0 0 1em 0;
  width: 48%;
`

const Company = styled.input`
  margin: 0 1em 1em 0;
  width: 48%;
`

const Phone = styled.input`
  margin: 0 0 1em 0;
  width: 48%;
`
const Address = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
`

const Message = styled.textarea`
  width: 100%;
  margin: 0 0 1em 0;
  line-height: 1.6;
  min-height: 250px;
  resize: vertical;
`

const Submit = styled.input`
  cursor: pointer;
  transition: 0.2s;
  background: #e9a53a;
  color: black;
  border-radius: 8px;
  padding: 10px;
  width: 100px;
  outline: none;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: bold;
`

const Modal = styled.div`
  background: white;
  padding: 2em;
  border-radius: 2px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const Button = styled.div`
  font-size: 1em;
  background: #e9a53a;
  display: inline-block;
  margin: 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 1em;
  border-radius: 8px;
  text-decoration: none;
  transition: 0.2s;
  z-index: 99;
  &:focus {
    outline: none;
  }
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      company: "",
      phone: "",
      address: "",
      email: "",
      message: "",
      showModal: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    fetch("/?no-cache=1", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: "",
      company: "",
      phone: "",
      address: "",
      email: "",
      message: "",
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <Layout>
        <SEO title="Contact Us - Sammons Industrial" />
        <h1 className="contact-us-header">Contact Us</h1>
        <div className="contact-container">
          <div className="form-container">
            <Form
              name="contact"
              onSubmit={this.handleSubmit}
              data-netlify="true"
              data-netlify-honeypot="bot"
              overlay={this.state.showModal}
              onClick={this.closeModal}
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot" onChange={this.handleInputChange} />
                </label>
              </p>

              <Name
                name="name"
                type="text"
                placeholder="Full Name"
                value={this.state.name}
                onChange={this.handleInputChange}
                required
              />
              <Email
                name="email"
                type="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
              <Company
                name="company"
                type="text"
                placeholder="Company"
                value={this.state.company}
                onChange={this.handleInputChange}
                required
              />
              <Phone
                name="phone"
                type="text"
                placeholder="Phone"
                value={this.state.phone}
                onChange={this.handleInputChange}
                required
              />
              <Address
                name="address"
                type="text"
                placeholder="Address"
                value={this.state.address}
                onChange={this.handleInputChange}
                required
              />
              <Message
                name="message"
                type="text"
                placeholder="Message"
                value={this.state.message}
                onChange={this.handleInputChange}
                required
              />
              <Submit name="submit" type="submit" value="Send" />

              <Modal visible={this.state.showModal}>
                <p>
                  Thank you for reaching out. I will get back to you as soon as
                  possible.
                </p>
                <Button onClick={this.closeModal}>Okay</Button>
              </Modal>
            </Form>
          </div>
          <div className="contact-info-container">
            <div className="phone">
              <h2>Phone</h2>
              <p>(214) 617-0000</p>
            </div>
            <div className="address">
              <h2>Address</h2>
              <p>
                14241 Dallas Parkway, Suite 880,
                <br />
                Dallas, Texas 75254
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
}

export default ContactForm

// export const pageQuery = graphql`
// {
//   allContentfulContactInfo {
//     edges {
//       node {
//         address
//         email
//         phone
//       }
//     }
//   }
// }

// `
